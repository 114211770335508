<template>
  <div class="box w-full mb-4 b-t-c">
    <div class="p-2">
      <div class="flex justify-between items-center">
        <span class="text-sm font-bold capitalize p-2">
          Tipo de Inversión {{ typeInvestmentStage }}
        </span>

        <div class="flex px-2 items-center rounded-md">
          <Button
            :icon="[isVisible ? 'pi pi-minus' : 'pi pi-plus']"
            class="p-button-rounded p-button-xs"
            :class="[isVisible ? 'p-button-warning' : 'p-button-success']"
            @click.prevent="stageDetails(numberStage, isVisible)"
          />
        </div>
      </div>

      <div class="flex flex-col items-center dark:bg-gray-900">
        <div class="grid bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Valor de Inversión</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(parseInt(totalValueStage)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-warning rounded border-warning border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Descuento</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(parseInt(investmentDiscountStage)) }}</div>
            </div>
          </div>

          <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Valor Invertido</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(parseInt(investmentValueStage)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-danger rounded border-danger border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Total Pagado  </div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(parseInt(totalPaymentStage)) }}</div>
            </div>
          </div>
          <div class="flex flex-col items-center bg-light-warning rounded border-warning border border-dashed p-2 m-2">
            <div class="text-sm text-gray-500 font-medium">Saldo Total</div>
            <div class="flex items-center pt-1">
              <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(parseInt(investmentValueStage-totalPaymentStage)) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isVisible"
      class="flex flex-col p-4 intro-y">
      <table-stage-details  :number-stage="numberStage" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'stageSupportItem',
  components: {
    TableStageDetails: defineAsyncComponent(() => import('../stageDetails'))
  },
  props: {
    numberStage: {
      type: Number,
      default: null
    },
    typeInvestmentStage: {
      type: String,
      default: ''
    },
    totalValueStage: {
      type: Number,
      default: 0
    },
    investmentDiscountStage: {
      type: Number,
      default: 0
    },
    investmentValueStage: {
      type: Number,
      default: 0
    },
    totalPaymentStage: {
      type: Number,
      default: 0
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const stageDetails = (numberStage, isVisible) => {
      context.emit('stageDetails', numberStage, isVisible)
    }
    return {
      stageDetails
    }
  }
}
</script>

<style lang="scss">

.border-dashed {
  border-style: dashed !important;
  border-color: #e4e6ef;
}
.bg-light-primary {
  background-color: #F1FAFF !important;
}
.bg-light-success {
  background-color: #e8fff3!important
}
.bg-light-info {
  background-color: #f8f5ff!important
}
.bg-light-warning {
  background-color: #fff8dd!important
}
.bg-light-danger {
  background-color: #fff5f8!important
}
.border-primary {
  border-color: #009ef7 !important;
}
.border-success {
  border-color: #50cd89!important
}

.border-info {
  border-color: #7239ea!important
}

.border-warning {
  border-color: #ffc700!important
}

.border-danger {
  border-color: #f1416c!important
}
.rounded {
  border-radius: .475rem !important;
}
.border-primary {
  border-color: #009ef7 !important;
}
.b-t-c {
  border-left-color: #00bcd4;
  border-left-width: 6px;
}
.swal2-container {
  z-index: 9999;
}
</style>
